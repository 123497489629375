



























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import { timeCompare } from '@/utils/validationHelpers'
import BaseSelect from '@/components/forms/BaseSelect/BaseSelect.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import { Condition, Model } from '@/store/modules/room.module'
import { namespace } from 'vuex-class'
import BaseTimepicker from '@/components/forms/BaseTimepicker.vue'
import breakpoints from '@/plugins/breakpoints'

const RoomStore = namespace('RoomStore')

const validations = {
  timeFrom: {
    required,
    // timeCompare: timeCompare('timeTo', '<'),
  },
  timeTo: {
    required,
    timeCompare: timeCompare('timeFrom', '>'),
  },
  min: { required: requiredIf('isVisitors'), minValue: minValue(1) },
  max: { required: requiredIf('isVisitors'), minValue: minValue(1) },
  price: { required, minValue: minValue(0) },
}

@Component({
  name: 'AddConditionForm',
  components: {
    BaseTimepicker,
    BaseSelect,
    FormGroup,
    Field,
    BaseButton,
    BaseCheckbox,
  },
  validations,
})
export default class AddConditionForm extends Vue {
  @Prop({ required: true })
  value!: Condition

  @Prop({ required: false })
  conditionForEdit!: any

  get isMobile(): boolean {
    return breakpoints.width <= 768
  }

  @RoomStore.State('model')
  model!: Model
  isDays = this.conditionForEdit?.fullday || true
  isTime = !this.conditionForEdit?.fullday || true
  isVisitors = this.conditionForEdit?.isVisitors || false
  timeFrom = this?.conditionForEdit?.from || '09:00'
  timeTo = this.conditionForEdit?.to || '21:00'
  min = this.conditionForEdit?.visitors?.min || null
  max = this.conditionForEdit?.visitors?.max || null
  units = this.conditionForEdit?.fullday ? 'day' : 'hour'
  fullday = this.conditionForEdit?.fullday || false
  price = this.conditionForEdit?.price || null
  id = this.conditionForEdit?.id || null

  days = [
    { name: 'Пн', checked: true, value: 1 },
    { name: 'Вт', checked: true, value: 2 },
    { name: 'Ср', checked: true, value: 3 },
    { name: 'Чт', checked: true, value: 4 },
    { name: 'Пт', checked: true, value: 5 },
    { name: 'Сб', checked: false, value: 6 },
    { name: 'Вс', checked: false, value: 7 },
  ]
  breakpoints = breakpoints

  get weekdays(): any {
    const days = this.days.filter((day) => day.checked)
    return days.map((day) => {
      return day.value
    })
  }

  get pricePlaceholderText(): string {
    return this.isMobile
      ? this.fullday
        ? 'Укажите цену за день'
        : 'Укажите цену за час'
      : ''
  }

  handleWeekDayClick(day: any): void {
    day.checked = !day.checked
    this.update()
  }

  @Watch('units')
  onUnitsChanged(val: any): void {
    this.fullday = val !== 'hour'
    this.update()
  }

  public update(): void {
    var args = {
      id: this.id,
      weekdays: this.weekdays,
      from: this.timeFrom,
      to: this.timeTo,
      isVisitors: !!this.isVisitors,
      visitors: this.isVisitors
        ? {
            min: this.min,
            max: this.max,
          }
        : null,
      fullday: this.fullday,
      price: this.price,
    }

    this.$emit('input', args)
  }

  created(): void {
    if (this.conditionForEdit?.weekdays) {
      this.days.forEach((day: any) => {
        if (this.conditionForEdit.weekdays.includes(day.value))
          day.checked = true
        else day.checked = false
      })
    }
    this.update()
  }

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }
}
