import { helpers, ValidationRule } from 'vuelidate/lib/validators'

export const timeCompare = (
  field: string,
  operator: string
): ValidationRule => {
  return helpers.withParams(
    { type: 'field', value: field },
    // eslint-disable-next-line
    function (value: string, vm: any) {
      //allow 00-00 time
      if (value === '00:00') {
        return true
      }
      const time1 = new Date(Date.parse('2010-10-10T' + value))
      const time2 = new Date(Date.parse('2010-10-10T' + vm[field]))

      switch (operator) {
        case '>':
          return time1 > time2
        case '<':
          return time1 < time2
        case '>=':
          return time1 >= time2
        case '<=':
          return time1 <= time2
        case '==':
          return time1 === time2
        default:
          return false
      }
    }
  )
}
